.notComplete {
    background-color: orange !important;
}

.complete {
    background-color: red !important;
}

.test {
    background-color: red;
}